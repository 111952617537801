const countries = [
  { key: 1, code: "af", label: "Afrikaans" },
  { key: 2, code: "sq", label: "Albanian" },
  { key: 3, code: "am", label: "Amharic" },
  { key: 4, code: "ar", label: "Arabic" },
  { key: 5, code: "hy", label: "Armenian" },
  { key: 6, code: "az", label: "Azerbaijani" },
  { key: 7, code: "eu", label: "Basque" },
  { key: 8, code: "be", label: "Belarusian" },
  { key: 9, code: "bn", label: "Bengali" },
  { key: 10, code: "bs", label: "Bosnian" },
  { key: 11, code: "bg", label: "Bulgarian" },
  { key: 12, code: "ca", label: "Catalan" },
  { key: 13, code: "ceb", label: "Cebuano" },
  { key: 14, code: "ny", label: "Chichewa" },
  { key: 15, code: "co", label: "Corsican" },
  { key: 16, code: "hr", label: "Croatian" },
  { key: 17, code: "cs", label: "Czech" },
  { key: 18, code: "da", label: "Danish" },
  { key: 19, code: "nl", label: "Dutch" },
  { key: 20, code: "en", label: "English" },
  { key: 21, code: "eo", label: "Esperanto" },
  { key: 22, code: "et", label: "Estonian" },
  { key: 23, code: "tl", label: "Filipino" },
  { key: 24, code: "fi", label: "Finnish" },
  { key: 25, code: "fr", label: "French" },
  { key: 26, code: "fy", label: "Frisian" },
  { key: 27, code: "gl", label: "Galician" },
  { key: 28, code: "ka", label: "Georgian" },
  { key: 29, code: "de", label: "German" },
  { key: 30, code: "el", label: "Greek" },
  { key: 31, code: "gu", label: "Gujarati" },
  { key: 32, code: "ht", label: "Haitian Creole" },
  { key: 33, code: "ha", label: "Hausa" },
  { key: 34, code: "haw", label: "Hawaiian" },
  { key: 35, code: "iw", label: "Hebrew" },
  { key: 36, code: "hi", label: "Hindi" },
  { key: 37, code: "hmn", label: "Hmong" },
  { key: 38, code: "hu", label: "Hungarian" },
  { key: 39, code: "is", label: "Icelandic" },
  { key: 40, code: "ig", label: "Igbo" },
  { key: 41, code: "id", label: "Indonesian" },
  { key: 42, code: "ga", label: "Irish" },
  { key: 43, code: "it", label: "Italian" },
  { key: 44, code: "ja", label: "Japanese" },
  { key: 45, code: "jw", label: "Javanese" },
  { key: 46, code: "kn", label: "Kannada" },
  { key: 47, code: "kk", label: "Kazakh" },
  { key: 48, code: "km", label: "Khmer" },
  { key: 49, code: "rw", label: "Kinyarwanda" },
  { key: 50, code: "ko", label: "Korean" },
  { key: 51, code: "ku", label: "Kurdish (Kurmanji)" },
  { key: 52, code: "ky", label: "Kyrgyz" },
  { key: 53, code: "lo", label: "Lao" },
  { key: 54, code: "la", label: "Latin" },
  { key: 55, code: "lv", label: "Latvian" },
  { key: 56, code: "lt", label: "Lithuanian" },
  { key: 57, code: "lb", label: "Luxembourgish" },
  { key: 58, code: "mk", label: "Macedonian" },
  { key: 59, code: "mg", label: "Malagasy" },
  { key: 60, code: "ms", label: "Malay" },
  { key: 61, code: "ml", label: "Malayalam" },
  { key: 62, code: "mt", label: "Maltese" },
  { key: 63, code: "mi", label: "Maori" },
  { key: 64, code: "mr", label: "Marathi" },
  { key: 65, code: "mn", label: "Mongolian" },
  { key: 66, code: "my", label: "Myanmar (Burmese)" },
  { key: 67, code: "ne", label: "Nepali" },
  { key: 68, code: "no", label: "Norwegian" },
  { key: 69, code: "or", label: "Odia (Oriya)" },
  { key: 70, code: "ps", label: "Pashto" },
  { key: 71, code: "fa", label: "Persian" },
  { key: 72, code: "pl", label: "Polish" },
  { key: 73, code: "pt", label: "Portuguese" },
  { key: 74, code: "pa", label: "Punjabi" },
  { key: 75, code: "ro", label: "Romanian" },
  { key: 76, code: "ru", label: "Russian" },
  { key: 77, code: "sm", label: "Samoan" },
  { key: 78, code: "gd", label: "Scots Gaelic" },
  { key: 79, code: "sr", label: "Serbian" },
  { key: 80, code: "st", label: "Sesotho" },
  { key: 81, code: "sn", label: "Shona" },
  { key: 82, code: "sd", label: "Sindhi" },
  { key: 83, code: "si", label: "Sinhala" },
  { key: 84, code: "sk", label: "Slovak" },
  { key: 85, code: "sl", label: "Slovenian" },
  { key: 86, code: "so", label: "Somali" },
  { key: 87, code: "es", label: "Spanish" },
  { key: 88, code: "su", label: "Sundanese" },
  { key: 89, code: "sw", label: "Swahili" },
  { key: 90, code: "sv", label: "Swedish" },
  { key: 91, code: "tg", label: "Tajik" },
  { key: 92, code: "ta", label: "Tamil" },
  { key: 93, code: "tt", label: "Tatar" },
  { key: 94, code: "te", label: "Telugu" },
  { key: 95, code: "th", label: "Thai" },
  { key: 96, code: "tr", label: "Turkish" },
  { key: 97, code: "tk", label: "Turkmen" },
  { key: 98, code: "uk", label: "Ukrainian" },
  { key: 99, code: "ur", label: "Urdu" },
  { key: 100, code: "ug", label: "Uyghur" },
  { key: 101, code: "uz", label: "Uzbek" },
  { key: 102, code: "vi", label: "Vietnamese" },
  { key: 103, code: "cy", label: "Welsh" },
  { key: 104, code: "xh", label: "Xhosa" },
  { key: 105, code: "yi", label: "Yiddish" },
  { key: 106, code: "yo", label: "Yoruba" },
  { key: 107, code: "zh", label: "Chinese" },
];

export default countries;
