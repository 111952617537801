import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import countries from "../lang_data/data";

export default function Result({ setOutputSelect, outputText }) {
  const [resultText, setResultText] = React.useState("");

  useEffect(() => {
    if (outputText == null) {
      setResultText("Translated text will be displayed here...");
    } else {
      setResultText(outputText);
    }
    return () => {
      // console.log("cleared");
    };
  }, [outputText]);

  return (
    <div className="">
      <div className="bg-sky-100 rounded-lg w-full h-[37vh] md:h-[40vh] md:w-[35vw] border-t-4 border-l-4 border-gray-600 border-opacity-70">
        <div className="p-5">
          <Autocomplete
            options={countries}
            onChange={(evt, val) => {
              setOutputSelect(val);
            }}
            autoHighlight={true}
            autoComplete={true}
            autoSelect={true}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 6, flexShrink: 0 } }}
                {...props}
              >
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Language"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <div className=" w-full border-2 border-gray-300 rounded-xl mt-5">
            <p className="text-center py-[5vh] sm:py-[7vh] px-5 text-md">
              {resultText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
