import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "../lang_data/data";

export default function CountrySelect({ setInputText, setSelectOption }) {
  const handleChange = (evt, val) => {
    setSelectOption(val);
  };

  return (
    <>
      <div className="bg-sky-100 rounded-lg w-full h-[35vh] md:h-[40vh] md:w-[35vw] border-b-4 border-r-4 border-gray-600 border-opacity-70">
        <div className="p-5 w-auto">
          <Autocomplete
            options={countries}
            onChange={handleChange}
            autoHighlight={true}
            autoComplete
            autoSelect={true}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 6, flexShrink: 0 } }}
                {...props}
              >
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                required={true}
                {...params}
                label="Choose a Language"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <div className="pt-4">
            <TextField
              label="Enter yout text here"
              required={true}
              color="secondary"
              type="text"
              id="text"
              onChange={(evt) => {
                setInputText(evt.target.value);
              }}
              variant="standard"
              fullWidth={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
