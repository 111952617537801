import { Alert, Button } from "@mui/material";
import axios from "axios";
import React from "react";
import Input from "./input/Input";
import Output from "./output/Output";

function Body() {
  // input select
  const [selectOption, setSelectOption] = React.useState(null);

  // handle text
  const [inputText, setInputText] = React.useState(null);

  // output select
  const [outputSelect, setOutputSelect] = React.useState(null);

  // output text
  const [outputText, setOutputText] = React.useState(null);
  const [netErrText, setNetErrText] = React.useState(null);

  // alert display
  const [alertState, setAlertState] = React.useState("none");

  // alert msg
  const [alertMsg, setAlertMsg] = React.useState(null);

  // validate data  // //http://localhost:5000
  const validateData = () => {
    if (
      selectOption !== null &&
      inputText !== null &&
      inputText !== "" &&
      outputSelect !== null
    ) {
      axios
        .get(
          `https://c04jds.deta.dev/trans/translate?input=${selectOption.code}&output=${outputSelect.code}&text=${inputText}`
        )
        .then((response) => {
          setOutputText(response.data.data.translatedText);
          // console.log(response.data);
        })
        .catch((err) => {
          if (err.message) {
            setOutputText(err.message);
          } else {
            setOutputText("check your netowork connection");
            // console.log(err.message);
          }
        });
    } else {
      // alert system
      setTimeout(() => {
        setAlertState("");
      }, 1);
      setTimeout(() => {
        setAlertState("none");
      }, 3000);
      setAlertState("none");
      // console.log("there is an err");
      setAlertMsg("please select an option or input some text");
    }
  };

  return (
    <div>
      <nav className="">
        <h1 className=" bg-sky-50 border-b-2 text-2xl font-bold text-center py-4 font-mono shadow-md">
          Translate your thoughts
        </h1>
      </nav>

      <div className="px-[3vw] w-full">
        {/* alert  */}
        <div className="mx-[10vw] sm:mx-[20vw] md:mx-[30vw] mt-5">
          <Alert
            sx={{
              display: alertState,
              width: 300,
            }}
            severity="error"
          >
            {alertMsg}
          </Alert>
        </div>
        {/* input option  */}
        <div className="pt-[12vh] mx-[6vw] sm:mx-[15vw] md:mx-[8vw] sm:pt-[15vh] md:flex md:justify-evenly  md:gap-5">
          <div>
            <Input
              setInputText={setInputText}
              setSelectOption={setSelectOption}
            />
          </div>

          {/* output option  */}
          <div className="pt-8">
            <Output
              setOutputSelect={setOutputSelect}
              outputText={outputText}
              netErrText={netErrText}
            />
          </div>
        </div>

        <div className="ml-[35vw] md:ml-[40vw] py-5">
          <Button
            onClick={() => {
              validateData();
              // console.log(alertState);
              /* console.log(selectOption.code, outputSelect.code, inputText); */
            }}
            type="submit"
            size="medium"
            variant="contained"
          >
            Translate
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Body;
